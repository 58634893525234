/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { db } from "./firebase";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import ModalTicket from "./Modal";
import {
  Affix,
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Input,
  Row,
  Tooltip,
  message,
  App,
  FloatButton,
  Alert,
  notification,
  Segmented,
  Drawer,
  Form,
  Tag,
  Typography,
} from "antd";
import ModalHelper from "./Helper";
import Marquee from "react-fast-marquee";

const status = {
  A: {
    label: "Asignado",
    bgColor: "bg-blue-100",
    color: "text-blue-500",
  },
  P: {
    label: "Pagado",
    bgColor: "bg-green-100",
    color: "text-green-500",
  },
  D: {
    label: "Delivery",
    bgColor: "bg-purple-100",
    color: "text-purple-500",
  },
  E: {
    label: "Entregado",
    bgColor: "bg-slate-100",
    color: "text-slate-500",
  },
};

notification.config({ maxCount: 1 });

const CorePeruanadas = () => {
  const { modal } = App.useApp();
  const clientInputRef = useRef(null);

  const [event, setEvent] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [products, setProducts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [searchStates, setSearchStates] = useState([]);
  const [searchNumbers, setSearchNumbers] = useState("");
  const [searchName, setSearchName] = useState("");
  const [isOpenFilterMobile, setIsOpenFilterMobile] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isOpenModalHelper, setIsOpenModalHelper] = useState(false);
  const [segmentedItem, setSegmentedItem] = useState("GENERAL");
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  const docRef = doc(db, "event", "dFnZZ8Zvjyv3rixxlSSI");

  const getEvent = () => {
    const refDoc = doc(db, "event", "dFnZZ8Zvjyv3rixxlSSI");
    const unsubscribe = onSnapshot(refDoc, (querySnapshot) => {
      const data = querySnapshot.data();
      setEvent(data);
      const tmpTickets = data.tickets;
      const orderedTickets = tmpTickets.sort((a, b) => a.number - b.number);
      setTickets(orderedTickets);
      const collectionRef = collection(docRef, "products");
      const unsubscribeProducts = onSnapshot(collectionRef, (snapshot) => {
        const items = [];
        snapshot.forEach((doc) => {
          const item = { id: doc.id, ...doc.data() };
          items.push(item);
        });
        setProducts(items);
      });
      const collectionGroupRef = collection(docRef, "groups");
      const unsubscribeGroups = onSnapshot(collectionGroupRef, (snapshot) => {
        const items = [];
        snapshot.forEach((doc) => {
          const item = { id: doc.id, ...doc.data() };
          items.push(item);
        });
        setGroups(items);
      });
    });
  };

  useEffect(() => {
    getEvent();
    // eslint-disable-next-line
  }, []);

  const onToggleFilterMobile = () => {
    // setIsOpenFilterMobile((prev) => !prev);
    setIsOpenDrawer((prev) => !prev);
  };

  const onToggleSidebar = () => {
    setIsOpenSidebar((prev) => !prev);
  };

  const onAddTicket = () => {
    setIsOpen(true);
  };

  const onEditTicket = (e, ticket) => {
    e.stopPropagation();
    setIsOpen(true);
    setSelectedTickets([]);
    setSelectedTicket(ticket);
  };

  const onCloseModal = () => {
    setIsOpen(false);
    setSelectedTicket(null);
  };

  const onCloseModalHelper = () => {
    setIsOpenModalHelper(false);
  };

  const filterByClient = (name) => {
    setSearchName(name);
    setIsAllChecked(false);
    setSelectedTickets([]);
  };

  const filterByStates = (values) => {
    setSearchStates(values);
    setIsAllChecked(false);
    setSelectedTickets([]);
  };

  const filterByTicketsNumber = (value) => {
    setSearchNumbers(value);
    setIsAllChecked(false);
    setSelectedTickets([]);
  };

  const onDeleteTicket = (e, ticketNumber) => {
    e.stopPropagation();
    modal.confirm({
      centered: true,
      title: "Mensaje de Confirmación",
      content: "¿Desea eliminar este ticket? Esta acción es irreversible.",
      okText: "Sí, eliminar",
      cancelText: "Cancelar",
      onOk: async () => {
        try {
          const newTickets = tickets.filter((t) => t.number !== ticketNumber);
          await updateDoc(docRef, { tickets: newTickets });
          message.success("El ticket ha sido eliminado");
        } catch (error) {
          message.error("Ha ocurrido un error al eliminar el ticket");
        }
      },
      // okButtonProps: { className: "bg-blue-600 hover:bg-blue-700" },
      onCancel: () => {},
    });
  };

  const toggleState = async (e, ticketNumber, newStatus) => {
    e.stopPropagation();
    setSelectedTickets([]);
    setIsAllChecked(false);
    try {
      let foundTicket = tickets.find((t) => t.number === ticketNumber);
      if (foundTicket) {
        let status = [...foundTicket.status];
        if (status.includes(newStatus)) {
          status = status.filter((e) => e !== newStatus);
        } else {
          status.push(newStatus);
        }
        foundTicket.status = status;
      }
      const updatedTickets = tickets.map((t) =>
        t.number === ticketNumber ? foundTicket : t
      );
      await updateDoc(docRef, { tickets: updatedTickets });
    } catch (error) {
      message.error("Ha ocurrido un error al actualizar la información");
    }
  };

  const toggleAllStates = async (newStatus) => {
    try {
      const hasAllStatus =
        selectedTickets.filter((t) => t.status.includes(newStatus)).length ===
        selectedTickets.length;
      const newArrayTickets = tickets.map((e) => {
        let foundItem = selectedTickets.find((a) => a.number === e.number);
        if (foundItem) {
          if (foundItem.status.includes(newStatus)) {
            /*if (!hasAllStatus) {
              return foundItem;
            }*/
            return {
              ...foundItem,
              status: foundItem.status.filter((x) => x !== newStatus),
            };
          } else {
            let element = {
              ...foundItem,
              status: [...foundItem.status, newStatus],
            };
            return element;
          }
        }
        return e;
      });
      await updateDoc(docRef, { tickets: newArrayTickets });
    } catch (error) {
      message.error("Ha ocurrido un error al actualizar la información");
    }
  };

  const onDeleteAllSelectedTickets = async () => {
    modal.confirm({
      centered: true,
      title: "Mensaje de Confirmación",
      content:
        "¿Desea eliminar los tickets seleccionados? Esta acción es irreversible.",
      okText: "Sí, eliminar",
      cancelText: "Cancelar",
      onOk: async () => {
        try {
          const newArrayTickets = tickets.filter(
            (t) => !selectedTickets.map((a) => a.number).includes(t.number)
          );
          await updateDoc(docRef, { tickets: newArrayTickets });
        } catch (error) {
          message.error("Ha ocurrido un error al actualizar la información");
        }
      },
      onCancel: () => {},
    });
  };

  const onToggleSelectAll = (isChecked) => {
    if (!isChecked) {
      setSelectedTickets([]);
      setIsAllChecked(false);
    } else {
      setSelectedTickets(filteredTickets);
      setIsAllChecked(true);
    }
  };

  const onSelectTicket = (ticket) => {
    let currentItems = [...selectedTickets];
    const foundInSelected = currentItems.find(
      (t) => t.number === ticket.number
    );
    if (foundInSelected) {
      currentItems = currentItems.filter(
        (t) => t.number !== foundInSelected.number
      );
    } else {
      currentItems.push(ticket);
    }
    setSelectedTickets(currentItems);
  };

  useEffect(() => {
    if (tickets) {
      const orderedTcks = tickets.sort((a, b) => a.number - b.number);
      let tmpFilter = orderedTcks;
      if (searchName !== "") {
        tmpFilter = orderedTcks.filter(
          (t) => t.client.toLowerCase().search(searchName.toLowerCase()) > -1
        );
      }
      let tmpSearchStates = [...searchStates];
      if (!searchStates.includes("A")) {
        tmpSearchStates.push("A");
        setSearchStates((prev) => ["A", ...prev]);
      }
      for (let i = 0; i < tmpSearchStates.length; i++) {
        tmpFilter = tmpFilter.filter((t) =>
          t.status.includes(tmpSearchStates[i])
        );
      }
      if (searchNumbers !== "") {
        const onlyNumbers = searchNumbers.split(",") || [];
        const rangeNumbers = [];
        for (let i = 0; i < onlyNumbers.length; i++) {
          if (onlyNumbers[i] !== "") {
            const isRangeNumbers = onlyNumbers[i].split("-");
            if (isRangeNumbers.length === 2) {
              let from = parseInt(isRangeNumbers[0]);
              let end = parseInt(isRangeNumbers[1]);
              if (from > end) {
                from = isRangeNumbers[1];
                end = isRangeNumbers[0];
              }
              for (let j = 0; j <= end - from; j++) {
                rangeNumbers.push(parseInt(from) + parseInt(j));
              }
            } else {
              rangeNumbers.push(parseInt(onlyNumbers[i]));
            }
          }
        }
        const setOfNumber = [...new Set([...rangeNumbers])];
        tmpFilter = tmpFilter.filter((t) => setOfNumber.includes(t.number));
      }
      const isInSelectedItems = tmpFilter.filter((t) =>
        selectedTickets.map((t) => t.number).includes(t.number)
      );
      setSelectedTickets(isInSelectedItems);
      setFilteredTickets(tmpFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets, searchStates, searchName, searchNumbers]);

  const segmentedOptions = [
    { label: "Todos", value: "GENERAL" },
    { label: "No Pagados", value: "PENDIENTE_PAGO" },
    { label: "Delivery", value: "DELIVERY" },
    { label: "Vendidos", value: "VENDIDOS" },
  ];

  const Filter = (
    <Affix offsetTop={73}>
      <div className="bg-white border-solid border-gray-200 p-4 rounded-md h-[calc(100vh-8rem)]">
        <div className="flex items-center mb-4">
          <i className="fal fa-search text-gray-400 mr-2" />
          <div className="uppercase font-bold">Filtro de búsqueda</div>
        </div>
        <Row gutter={[16, 16]}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="hidden md:block"
          >
            <Segmented options={segmentedOptions} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Input
              value={searchName}
              type="text"
              onChange={(e) => filterByClient(e.target.value)}
              placeholder="Buscar por cliente"
              required
              allowClear
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Input
              allowClear
              placeholder="N° Tickets"
              onChange={(e) => filterByTicketsNumber(e.target.value)}
            />
          </Col>
          <Col span={24} className="hidden lg:block">
            <Row gutter={[16, 16]} className="items-center md:items-start h-10">
              <Col lg={24} xs={14}>
                <Checkbox
                  onClick={(e) => onToggleSelectAll(e.target.checked)}
                  checked={isAllChecked}
                  className="text-xs md:text-sm"
                >
                  Seleccionar todos ({filteredTickets.length})
                </Checkbox>
              </Col>
              {selectedTickets.length > 1 && (
                <Col lg={24} xs={10}>
                  <div className="flex items-center justify-end">
                    <div
                      onClick={onDeleteAllSelectedTickets}
                      className="rounded-lg bg-red-100 p-2 w-8 h-8 hover:cursor-pointer hover:bg-red-200 hidden lg:block"
                    >
                      <div className="-mt-[2px]">
                        <i className="fas fa-trash-alt text-red-500 ml-[2px]" />
                      </div>
                    </div>
                    <div className="ml-auto flex items-center">
                      <Tooltip title="Delivery" placement="bottomLeft">
                        <div
                          onClick={() => toggleAllStates("D")}
                          className={`rounded-lg p-2 w-8 h-8 hover:cursor-pointer bg-neutral-200 hover:bg-neutral-500 hover:white-text`}
                        >
                          <div className="-mt-[2px]">
                            <i
                              className={`fas fa-moped text-neutral-500 -ml-[1px]`}
                            />
                          </div>
                        </div>
                      </Tooltip>
                      <Divider className="mx-2" type="vertical" />
                      <Tooltip title="Entregado" placement="bottom">
                        <div
                          onClick={() => toggleAllStates("E")}
                          className={`rounded-lg p-2 w-8 h-8 hover:cursor-pointer bg-neutral-200 hover:bg-neutral-500 hover:white-text`}
                        >
                          <div className="-mt-[2px]">
                            <i
                              className={`fas fa-hand-holding-box text-neutral-500 -ml-[1px]`}
                            />
                          </div>
                        </div>
                      </Tooltip>
                      <Divider className="mx-2" type="vertical" />
                      <Tooltip title="Pagado" placement="bottomRight">
                        <div
                          onClick={() => toggleAllStates("P")}
                          className={`rounded-lg p-2 w-8 h-8 hover:cursor-pointer bg-neutral-200 hover:bg-neutral-500 hover:white-text`}
                        >
                          <div className="-mt-[2px]">
                            <i
                              className={`fas fa-cash-register text-neutral-500 ml-[1px]`}
                            />
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={24} className="text-xs hidden">
            <Divider />
            <div className="font-semibold uppercase mb-1">Leyenda</div>
            <div className="flex">
              <div className="flex items-center">
                <div className="w-2 h-2 bg-blue-500 mr-1" />
                Asignado
              </div>
              <div className="flex items-center ml-2">
                <div className="w-2 h-2 bg-green-500 mr-1" />
                Pagado
              </div>
              <div className="flex items-center ml-2">
                <div className="w-2 h-2 bg-purple-500 mr-1" />
                Delivery
              </div>
              <div className="flex items-center ml-2">
                <div className="w-2 h-2 bg-slate-500 mr-1" />
                Entregado
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Affix>
  );

  const formItemLayout = {
    labelCol: {
      xs: { span: 4 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 20 },
      sm: { span: 20 },
    },
  };

  return (
    <div className="bg-neutral-50">
      <Affix offsetTop={0.1}>
        <div>
          <Alert
            banner
            closable
            className="md:hidden"
            message={
              <Marquee pauseOnHover gradient={false}>
                Importante: Esta es una versión de prueba y puede contener
                errores.
                <i className="fal fa-long-arrow-right mx-4" />
              </Marquee>
            }
          />
          <div className="bg-white px-4 lg:px-4 py-2 shadow-md">
            <div className="flex items-center font-bold text-lg">
              <div
                className="bg-gray-100 hover:bg-gray-200 hover:cursor-pointer px-4 py-[2px] rounded-md"
                onClick={onToggleSidebar}
              >
                <i className="far fa-bars text-sm" />
              </div>
              <div className="h-10 flex items-center mx-auto md:ml-4 md:mr-auto">
                <div className="text-xl c-title">Peruanadas</div>
              </div>
              <div className="flex items-center space-x-2">
                <Button type="primary" onClick={onAddTicket}>
                  <div className="block lg:hidden">
                    <i className="far fa-file-plus" />
                  </div>
                  <div className="hidden lg:block">Agregar Ticket</div>
                </Button>
              </div>
            </div>
            <div
              className={`${
                isOpenFilterMobile ? "block" : "hidden"
              } lg:hidden mt-4`}
            >
              {Filter}
            </div>
          </div>
          <div className="mb-4 md:hidden">
            <Segmented block className="text-xs" options={segmentedOptions} />
          </div>
        </div>
      </Affix>
      <Drawer placement="left" onClose={onToggleSidebar} open={isOpenSidebar}>
        menu lateral
      </Drawer>
      <Drawer
        autoFocus={false}
        closable={false}
        placement="top"
        onClose={onToggleFilterMobile}
        open={isOpenDrawer}
        height={200}
        forceRender
        afterOpenChange={(isVisible) => {
          if (isVisible && !searchName && !searchNumbers) {
            clientInputRef.current.focus();
          }
        }}
      >
        <div className="flex items-center mb-8">
          <div className="uppercase font-bold">Filtro de búsqueda</div>
          <i
            className="ml-auto fal fa-close text-lg text-gray-400 mr-2"
            onClick={onToggleFilterMobile}
          />
        </div>
        <Form {...formItemLayout} layout="horizontal">
          <Form.Item name="client" label="Cliente" className="mb-2">
            <Input
              autoFocus
              value={searchName}
              ref={clientInputRef}
              onChange={(e) => filterByClient(e.target.value)}
              placeholder="Buscar por nombre y/o apellidos"
              allowClear
            />
          </Form.Item>
          <Form.Item name="ticketNumbers" label="Tickets" className="mb-0">
            <Input
              allowClear
              value={searchNumbers}
              placeholder="Rango: 10-20, Nros: 21,22"
              onChange={(e) => filterByTicketsNumber(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Drawer>

      <div className="grid lg:grid-cols-4 gap-4 px-4 pb-24 md:pb-16 mt-4">
        <div className="col-span-1 hidden lg:block">{Filter}</div>
        <div className="col-span-3">
          {filteredTickets.length === 0 && (
            <div className="w-full justify-center h-[calc(100vh-16rem)] md:h-[calc(100vh-10rem)] flex items-center">
              <Empty />
            </div>
          )}
          {filteredTickets.length > 0 && (
            <>
              <div className="grid lg:grid-cols-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
                {filteredTickets.map((t, k) => {
                  const hasDStatus = t.status.includes("D");
                  const hasEStatus = t.status.includes("E");
                  const hasPStatus = t.status.includes("P");
                  return (
                    <div
                      key={k}
                      onClick={!hasPStatus ? () => onSelectTicket(t) : null}
                      className={`element shadow-lg hover:shadow-xl overflow-hidden bg-white text-center ring-2 rounded-lg hover:cursor-pointer relative ${
                        selectedTickets.map((e) => e.number).includes(t.number)
                          ? "ring-2 ring-orange-400 bg-slate-100"
                          : "ring-transparent"
                      }`}
                    >
                      {hasPStatus && <div className="watermark">VENDIDO</div>}
                      <div className="text-left">
                        <div className="p-4">
                          <div className="text-4xl font-bold">
                            #{t.number.toString().padStart(2, "0")}
                          </div>
                          <div className="uppercase font-bold mb-2 mt-2 w-full flex items-center">
                            {event?.description ?? ""}
                          </div>
                          <div className="mb-0 w-full flex items-center">
                            <div className="w-14">Cliente</div>
                            <div className="uppercase ml-2 w-[80%]">
                              :{" "}
                              <Typography.Text ellipsis className="w-[80%]">
                                {t.client}
                              </Typography.Text>
                            </div>
                          </div>
                          <div className="mb-0 w-full flex items-center">
                            <div className="w-14">Producto</div>
                            <div className="uppercase ml-2">: {t.product}</div>
                          </div>
                          {t.group && (
                            <div className="mb-0 w-full flex items-center">
                              <div className="w-14">Grupo</div>
                              <div className="uppercase ml-2">
                                : {t.group.name}
                              </div>
                            </div>
                          )}
                          <div className="p-2 mb-2 ff-pricing text-lg rounded-tr-lg rounded-bl-lg absolute right-4 top-4 py-1 px-2 bg-orange-100">
                            S/. {t.price.toFixed(2)}
                          </div>
                        </div>
                        <div className="h-1 border-t border-solid border-gray-300 border-b-0 border-r-0 border-l-0" />
                        <div className="flex items-center justify-between space-x-2 p-4">
                          <div
                            onClick={(e) => toggleState(e, t.number, "D")}
                            className={`flex items-center rounded justify-center ${
                              hasDStatus
                                ? "bg-indigo-500 text-white border-dashed border-indigo-500"
                                : "bg-white border-dashed border-gray-300 hover:border-gray-500"
                            } p-2 w-1/3 h-8 hover:cursor-pointer`}
                          >
                            <div className="-mt-[2px]">
                              <i
                                className={`fas fa-moped ${
                                  hasDStatus ? "text-white" : ""
                                }`}
                              />
                            </div>
                            <div className="ml-2">Delivery</div>
                          </div>
                          <div
                            onClick={(e) => toggleState(e, t.number, "E")}
                            className={`flex items-center rounded justify-center ${
                              hasEStatus
                                ? "bg-blue-500 text-white border-dashed border-blue-500"
                                : "bg-white border-dashed border-gray-300 hover:border-gray-500"
                            } p-2 w-1/3 h-8 hover:cursor-pointer`}
                          >
                            <div className="-mt-[2px]">
                              <i
                                className={`fas fa-hand-holding-box ${
                                  hasEStatus ? "text-white" : ""
                                }`}
                              />
                            </div>
                            <div className="ml-2">Entregado</div>
                          </div>
                          <div
                            onClick={(e) => toggleState(e, t.number, "P")}
                            className={`flex items-center rounded justify-center ${
                              hasPStatus
                                ? "bg-teal-500 text-white border-dashed border-teal-500"
                                : "bg-white border-dashed border-gray-300 hover:border-gray-500"
                            } p-2 w-1/3 h-8 hover:cursor-pointer`}
                          >
                            <div className="-mt-[2px]">
                              <i
                                className={`fas fa-cash-register ${
                                  hasPStatus ? "text-white" : ""
                                }`}
                              />
                            </div>
                            <div className="ml-2">Pagado</div>
                          </div>
                        </div>
                        {(!selectedTickets
                          .map((e) => e.number)
                          .includes(t.number) ||
                          selectedTickets.length === 1) &&
                          !hasPStatus &&
                          !hasEStatus && (
                            <div className="absolute top-16 space-y-2 right-4">
                              <div
                                onClick={(e) => onEditTicket(e, t)}
                                className="rounded-lg bg-gray-100 p-2 w-8 h-8 hover:cursor-pointer hover:bg-gray-200"
                              >
                                <div className="mt-[1px] ml-[1px] flex">
                                  <i className="fas fa-pencil text-gray-500" />
                                </div>
                              </div>
                              <div
                                onClick={(e) => onDeleteTicket(e, t.number)}
                                className="rounded-lg bg-red-100 p-2 w-8 h-8 hover:cursor-pointer hover:bg-red-200"
                              >
                                <div className="mt-[1px] ml-[2px] flex">
                                  <i className="fas fa-trash-alt text-red-500" />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="fixed bottom-0 z-10 w-full bg-[#ff611d] md:bg-white text-white md:text-black px-4 text-sm lg:px-4 py-2 flex items-center c-footer">
        <div>
          <span className="mr-2">Total:</span>
          {"S/. " +
            tickets
              .reduce(
                (total, item) =>
                  total + (item.status.includes("P") ? item.price : 0),
                0
              )
              .toFixed(2)}
        </div>
        <div className="ml-auto"># Tickets: {tickets.length}</div>
        <Divider type="vertical" className="bg-white mx-2" />
        <div
          className="cursor-pointer flex items-center"
          onClick={() => setIsOpenModalHelper(true)}
        >
          Ayuda
          <i className="far fa-question-circle ml-2" />
        </div>
      </div>
      <FloatButton
        onClick={() =>
          notification.info({
            message: "¡Lo sentimos!",
            description:
              "La funcionalidad para realizar una venta se encuentra en mantenimiento.",
            placement: "top",
            icon: <i className="far fa-face-worried" />,
          })
        }
        shape="square"
        type="primary"
        className="md:hidden -mr-2"
        icon={<i className="far fa-cart-plus -ml-[1px] mt-[2px]" />}
      />
      <FloatButton
        onClick={onToggleFilterMobile}
        shape="square"
        className="md:hidden left-16"
        icon={<i className="far fa-search -ml-[1px] mt-[2px]" />}
      />

      <FloatButton.Group shape="square" className="md:hidden left-4 -mr-2">
        {selectedTickets.length > 1 && (
          <>
            <FloatButton
              onClick={() => toggleAllStates("D")}
              icon={<i className="far fa-moped" />}
            />
            <FloatButton
              onClick={() => toggleAllStates("E")}
              icon={<i className="far fa-hand-holding-box" />}
            />
            <FloatButton
              onClick={() => toggleAllStates("P")}
              icon={<i className="far fa-cash-register" />}
            />
            <FloatButton
              onClick={onDeleteAllSelectedTickets}
              icon={<i className="far fa-trash-alt text-red-500" />}
            />
          </>
        )}
        <FloatButton
          shape="square"
          onClick={() => onToggleSelectAll(!isAllChecked)}
          type={isAllChecked ? "primary" : "default"}
          className="md:hidden -mr-2"
          icon={<i className="far fa-list-check -ml-[1px] mt-[2px]" />}
        />
      </FloatButton.Group>
      <ModalTicket
        isOpen={isOpen}
        onClose={onCloseModal}
        allTickets={tickets}
        ticketsInBoard={filteredTickets}
        ticket={selectedTicket}
        products={products}
        groups={groups}
      />

      <ModalHelper isOpen={isOpenModalHelper} onClose={onCloseModalHelper} />
    </div>
  );
};

export default CorePeruanadas;
