import React, { useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  Modal,
  Row,
  App,
} from "antd";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";

const ModalTicket = ({
  isOpen,
  onClose,
  ticketsInBoard = [],
  allTickets = [],
  ticket = null,
  products = [],
  groups = [],
}) => {
  const [form] = Form.useForm();
  const { modal, message } = App.useApp();

  const onFinish = async (values) => {
    const { client, productId, price, tickets, status, groupId } = values;
    if (!ticket) {
      const onlyNumbers = tickets.split(",") || [];
      const rangeNumbers = [];
      for (let i = 0; i < onlyNumbers.length; i++) {
        const isRangeNumbers = onlyNumbers[i].split("-");
        if (isRangeNumbers.length > 1) {
          let from = parseInt(isRangeNumbers[0]);
          let end = parseInt(isRangeNumbers[1]);
          if (from > end) {
            from = isRangeNumbers[1];
            end = isRangeNumbers[0];
          }
          for (let j = 0; j <= end - from; j++) {
            rangeNumbers.push(parseInt(from) + parseInt(j));
          }
        } else {
          rangeNumbers.push(parseInt(onlyNumbers[i]));
        }
      }
      const setOfNumber = [...new Set([...rangeNumbers])];
      // validar si los numeros ya existen en el array de tickets
      const exists = setOfNumber.filter((t) =>
        ticketsInBoard.some((a) => a.number === t)
      );
      if (exists.length === 0) {
        modal.confirm({
          centered: true,
          title: "Mensaje de Confirmación",
          content:
            "¿Desea registrar estos tickets? Puede eliminarlos más adelante.",
          okText: "Sí, registrar",
          cancelText: "Cancelar",
          onOk: async () => {
            try {
              const finalElements = setOfNumber.filter(
                (e) => !exists.includes(e)
              );
              const arrayElements = finalElements.map((number) => {
                const product = products.find((p) => p.id === productId);
                const group = groups.find((g) => g.id === groupId);
                return {
                  number,
                  client: client.trim(),
                  productId: product?.id ?? "",
                  product: product?.name ?? "",
                  price,
                  group,
                  status,
                };
              });
              const arrayWithRegistered = [...arrayElements, ...allTickets];
              const orderedElements = arrayWithRegistered.sort(
                (a, b) => a.number - b.number
              );
              const docRef = doc(db, "event", "dFnZZ8Zvjyv3rixxlSSI");
              await updateDoc(docRef, { tickets: orderedElements });
              message.success("Los tickets fueron registrados.");
              form.resetFields();
              onClose();
            } catch (error) {
              message.error("Ha ocurrido un error al registrar tickets");
            }
          },
          // okButtonProps: { className: "bg-blue-600 hover:bg-blue-700" },
          onCancel: () => {},
        });
      } else {
        message.warning("Algunos tickets ya fueron registrados");
      }
    } else {
      modal.confirm({
        centered: true,
        title: "Mensaje de Confirmación",
        content: "¿Desea actualizar la información de este ticket?",
        okText: "Sí, guardar",
        cancelText: "Cancelar",
        onOk: async () => {
          try {
            const netTickets = ticketsInBoard.map((t) => {
              if (t.number === ticket.number) {
                const product = products.find((p) => p.id === productId);
                const group = groups.find((g) => g.id === groupId);
                return {
                  number: ticket.number,
                  client: client.trim(),
                  productId: product?.id ?? "",
                  product: product?.name ?? "",
                  price,
                  group,
                  status,
                };
              }
              return t;
            });
            const orderedElements = netTickets.sort(
              (a, b) => a.number - b.number
            );
            const docRef = doc(db, "event", "dFnZZ8Zvjyv3rixxlSSI");
            await updateDoc(docRef, { tickets: orderedElements });
            message.success("El ticket ha sido actualizado.");
            form.resetFields();
            onClose();
          } catch (error) {
            message.error("Ha ocurrido un error al registrar tickets");
          }
        },
        // okButtonProps: { className: "bg-blue-600 hover:bg-blue-700" },
        onCancel: () => {},
      });
    }
  };

  const onSelectProduct = (id) => {
    const foundProduct = products.find((p) => p.id === id);
    if (foundProduct) {
      form.setFieldsValue({ price: foundProduct.price });
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (ticket) {
        const { productId, price, status, client, number, group } = ticket;
        form.setFieldsValue({
          client,
          productId,
          price,
          status,
          tickets: number,
          groupId: group?.id,
        });
      } else {
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, ticket]);

  return (
    <Modal
      centered
      title={
        <div className="font-bold">{`${
          ticket
            ? `Editar Ticket #${ticket.number.toString().padStart(2, "0")}`
            : "Registrar Ticket"
        }`}</div>
      }
      open={isOpen}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ flex: "110px" }}
        className="mt-4"
        colon={false}
        // requiredMark="optional"
        initialValues={{ status: ["A"] }}
      >
        <Form.Item
          name="client"
          label="Cliente"
          className="mb-4"
          rules={[{ required: true, message: "Requerido" }]}
        >
          <Input allowClear autoFocus />
        </Form.Item>
        <Form.Item
          name="tickets"
          label="Nro Tickets"
          className="mb-4"
          rules={[{ required: true, message: "Requerido" }]}
        >
          <Input
            className="c-input"
            placeholder="Rango: 1-20, Nros: 1,2,4,5"
            disabled={ticket !== null}
          />
        </Form.Item>
        <Row gutter={[16, 0]}>
          <Col xs={16} sm={24} md={24} lg={24}>
            <Form.Item
              name="productId"
              label="Producto"
              className="mb-4"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <Select
                allowClear
                placeholder="Seleccione"
                popupMatchSelectWidth={false}
                optionLabelProp="searchable"
                optionFilterProp="searchable"
                onSelect={onSelectProduct}
              >
                {products
                  .filter((p) => p.withTicket)
                  .map((p) => {
                    return (
                      <Select.Option key={p.id} searchable={p.name}>
                        <div className="flex items-center">
                          <div>{p.name}</div>
                          <div className="ml-auto">S/.{p.price.toFixed(2)}</div>
                        </div>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8} sm={24} md={24} lg={24}>
            <Form.Item
              name="price"
              label="Precio (S/.)"
              className="mb-4"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <InputNumber min={1} className="w-full" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="groupId" label="Grupo" className="mb-4">
              <Select
                placeholder="Seleccione"
                popupMatchSelectWidth={false}
                allowClear
                optionLabelProp="searchable"
                optionFilterProp="searchable"
                onSelect={onSelectProduct}
              >
                {groups.map((g) => {
                  return (
                    <Select.Option key={g.id} searchable={g.name}>
                      <div className="flex items-center">
                        <div>{g.name}</div>
                        <div className="ml-auto">
                          <div
                            className="h-2 w-2 border bg-red-400"
                            style={{ backgroundColor: g.color }}
                          />
                        </div>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="status"
              label="Estado"
              // className="mb-4"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <Select
                mode="multiple"
                placeholder="Seleccione"
                maxTagCount="responsive"
                showSearch={false}
              >
                <Select.Option key="A" disabled>
                  Asignado
                </Select.Option>
                <Select.Option key="P">Pagado</Select.Option>
                <Select.Option key="E">Entregado</Select.Option>
                <Select.Option key="D">Delivery</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label=" " className="hidden sm:block mb-0">
          <div className="space-x-2">
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
            <Button onClick={onClose}>Cancelar</Button>
          </div>
        </Form.Item>
        <div className="space-y-2 sm:hidden">
          <Button block type="primary" htmlType="submit">
            Guardar
          </Button>
          <Button onClick={onClose} block>
            Cancelar
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalTicket;
