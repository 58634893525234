import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "antd/dist/reset.css";
import "./icons/fontawesome/css/all.min.css";
import reportWebVitals from "./reportWebVitals";
import { App, ConfigProvider } from "antd";
import CorePeruanadas from "./Core";
import esES from "antd/locale/es_ES";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    locale={esES}
    theme={{
      token: {
        // Seed Token
        colorPrimary: "#ff611d",
      },
    }}
  >
    <App>
      <CorePeruanadas />
    </App>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
