import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBfd_X0CB8VN-JoR7Gkf5IE33Dc58bKs4I",
  authDomain: "grill-conssy.firebaseapp.com",
  projectId: "grill-conssy",
  storageBucket: "grill-conssy.appspot.com",
  messagingSenderId: "435800579776",
  appId: "1:435800579776:web:aedbb3a23413591824d725",
  measurementId: "G-7QDT50N09B"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
getAnalytics(app);

export { db, auth };
