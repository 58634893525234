import { Alert, Divider, Modal } from "antd";

const ModalHelper = ({ isOpen, onClose }) => {
  return (
    <Modal
      centered
      title={<div className="font-bold">Manual de Ayuda</div>}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      bodyStyle={{ padding: 0 }}
    >
      <div className="space-y-3 mt-4">
        <Alert
          showIcon={false}
          message={
            <div className="flex items-center">
              <div className="rounded-full p-2 px-3 w-10 h-10 hover:cursor-pointer bg-neutral-300">
                <div className="mt-[2px]">
                  <i className={`fas fa-truck-fast text-neutral-500`} />
                </div>
              </div>
              <div className="ml-4">
                <div className="font-bold">Botón Delivery</div>
                <div className="text-gray-600 leading-4 text-xs md:text-sm">
                  Esta acción es usada para indicar si el ticket será entregado
                  como delivery.
                </div>
              </div>
            </div>
          }
        />
        <Alert
          showIcon={false}
          message={
            <div className="flex items-center">
              <div className="rounded-full p-2 px-3 w-10 h-10 hover:cursor-pointer bg-neutral-300">
                <div className="mt-[2px]">
                  <i className={`fas fa-box-full text-neutral-500`} />
                </div>
              </div>
              <div className="ml-4">
                <div className="font-bold">Botón Entregado</div>
                <div className="text-gray-600 leading-4 text-xs md:text-sm">
                  Esta acción es usada para indicar si el producto se ha sido
                  entregado al cliente.
                </div>
              </div>
            </div>
          }
        />
        <Alert
          showIcon={false}
          message={
            <div className="flex items-center">
              <div className="rounded-full p-2 px-3 w-10 h-10 hover:cursor-pointer bg-neutral-300">
                <div className="mt-[2px]">
                  <i
                    className={`fas fa-cash-register text-neutral-500 ml-[2px]`}
                  />
                </div>
              </div>
              <div className="ml-4">
                <div className="font-bold">Botón Pagado</div>
                <div className="text-gray-600 leading-4 text-xs md:text-sm">
                  Esta acción es usada para indicar si el ticket fue pagado en
                  su totalidad.
                </div>
              </div>
            </div>
          }
        />
      </div>
      <Divider />
      <div className="md:text-sm text-xs mb-3">
        Si el botón de acción utlizado cambia al color verde, esto indica que el
        estado del ticket ha sido actualizado y se encuentra activo.
      </div>
      <div className="flex items-center">
        <div className="rounded-full p-2 px-3 w-10 h-10 hover:cursor-pointer bg-neutral-200">
          <div className="mt-[2px]">
            <i className={`fas fa-cash-register text-neutral-500 ml-[2px]`} />
          </div>
        </div>
        <i className="far fa-arrow-right mx-4" />
        <div className="rounded-full p-2 px-3 w-10 h-10 hover:cursor-pointer bg-green-500 white-text">
          <div className="mt-[2px]">
            <i className={`fas fa-cash-register text-neutral-500 ml-[2px]`} />
          </div>
        </div>
      </div>
      <Divider />
      <div className="md:text-sm text-xs mb-3">
        Si el botón de acción utlizado cambia al color verde, esto indica que el
        estado del ticket ha sido actualizado y se encuentra inactivo.
      </div>
      <div className="flex items-center">
        <div className="rounded-full p-2 px-3 w-10 h-10 hover:cursor-pointer bg-green-500 white-text">
          <div className="mt-[2px]">
            <i className={`fas fa-cash-register text-neutral-500 ml-[2px]`} />
          </div>
        </div>
        <i className="far fa-arrow-right mx-4" />
        <div className="rounded-full p-2 px-3 w-10 h-10 hover:cursor-pointer bg-neutral-200">
          <div className="mt-[2px]">
            <i className={`fas fa-cash-register text-neutral-500 ml-[2px]`} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalHelper;
